import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {  Subscription, timer } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Location,  } from '@angular/common';
import { MessageModel } from '../models/message.model';
import { SessionService } from '../services/session.service';
import { RestService } from '../services/rest.service';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';


export interface IMisc {
    navbar_menu_visible: number;
    active_collapse: boolean,
    disabled_collapse_init: number,
    sidebar_mini_active?: boolean
    hide_sidebar_active?: boolean
}

const misc: IMisc = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};


@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
   
    location: Location;
    mobile_menu_visible = 0;
    private nativeElement: Node;
    private toggleButton: Element;
    private sidebarVisible: boolean;
    private _router: Subscription;

    messages: MessageModel[];
    notificationSubscription: Subscription;
    timerSubscription: Subscription;

    constructor(
        location: Location, 
        private renderer: Renderer2, 
        private element: ElementRef, 
        private router: Router,
        public session: SessionService,
        private _rest: RestService<MessageModel>) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }
    minimizeSidebar(){
      const body = document.getElementsByTagName('body')[0];

      if (misc.sidebar_mini_active === true) {
          body.classList.remove('sidebar-mini');
          misc.sidebar_mini_active = false;

      } else {
          setTimeout(function() {
              body.classList.add('sidebar-mini');

              misc.sidebar_mini_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }
    hideSidebar(){
      const body = document.getElementsByTagName('body')[0];
      const sidebar = document.getElementsByClassName('sidebar')[0];

      if (misc.hide_sidebar_active === true) {
          setTimeout(function() {
              body.classList.remove('hide-sidebar');
              misc.hide_sidebar_active = false;
          }, 300);
          setTimeout(function () {
              sidebar.classList.remove('animation');
          }, 600);
          sidebar.classList.add('animation');

      } else {
          setTimeout(function() {
            body.classList.add('hide-sidebar');
              // $('.sidebar').addClass('animation');
              misc.hide_sidebar_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }

    ngOnInit() {
       
        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
          this.sidebarClose();

          const $layer = document.getElementsByClassName('close-layer')[0];
          if ($layer) {
            $layer.remove();
          }
          
        });
        this.checkMessages();

        const token = localStorage.getItem('token');
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(environment.SERVER_URL + "/messages/statuthub",{ accessTokenFactory: () => token })
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect()
        .build();

        connection.start().then().catch((err) =>  console.error(err.toString()));
        connection.on("NewMessage", ()=> {
            this.checkMessages();
        })

        connection.on("LogoutApp", ()=> {
            this.session.signout(() => {
                setTimeout(() => {
                    this.router.navigate(['/pages/login']);
                }, 500);
            });
        })

    }

    loadNext() {
        this.timerSubscription = timer(30000).pipe(
            first()
          ).subscribe(() => this.checkMessages());
    }
    
    checkMessages() {
        
        this.notificationSubscription = this._rest.get('/messages/statut', {}).subscribe((data) => {
            this.messages = data;
            this.session.updateMessagesNotif(this.messages);
            //this.loadNext();
        });
    }
    
    onResize() {
      if ($(window).width() > 991) {
        return false;
      }
      return true;
    }
    sidebarOpen() {
      const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function() {
            $toggle.classList.add('toggled');
        }, 430);

        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        }else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function() {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function() { //asign a function
          body.classList.remove('nav-open');
          this.mobile_menu_visible = 0;
          this.sidebarVisible = false;

          $layer.classList.remove('visible');
          setTimeout(function() {
              $layer.remove();
              $toggle.classList.remove('toggled');
          }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    }
    sidebarClose() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function() {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
}