import { Routes, mapToCanActivate } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './shared/services/auth.guard';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'pages/login',
      pathMatch: 'full',
    }, {
      path: '',
      component: AdminLayoutComponent,
      children: [
    {
        path: '',
        loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
    }, 
    {
        path: 'dashboard',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['employee'] },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'professionals',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin', 'employee'] },
        loadChildren: () => import('./professionals/professionals.module').then(m => m.ProfessionalsModule)
    },
    {
        path: 'profil',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse','opticien','admin', 'employee','nurseSub','taxi'] },
        loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
    },
    {
        path: 'ordinances',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin', 'nurse', 'employee','nurseSub'] },
        loadChildren: () => import('./ordinances/ordinances.module').then(m => m.OrdinancesModule)
    },
    {
        path: 'documents',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin', 'employee'] },
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
    },
    {
        path: 'transports',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin', 'employee','taxi'] },
        loadChildren: () => import('./transports/transports.module').then(m => m.TransportsModule)
    },
    {
        path: 'vehicles',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin', 'employee','taxi'] },
        loadChildren: () => import('./vehicles/vehicles.module').then(m => m.VehiclesModule)
    },
    {
        path: 'motifs',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin'] },
        loadChildren: () => import('./motifs/motifs.module').then(m => m.MotifsModule)
    },
    {
        path: 'patients',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse','employee','admin','nurseSub','taxi'] },
        loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule)
    },
    {
        path: 'invoices',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse', 'opticien','taxi'] },
        loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule)
    },
    {
       path: 'invoices-to-secure',
       canActivate: mapToCanActivate([AuthGuard]),
       data: { roles: ['employee','admin'] },
       loadChildren: () => import('./invoices-to-secure/invoices-to-secure.module').then(m => m.InvoicesToSecureModule)
   },
   {
        path: 'attestation-fiscale',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['opticien', 'nurse','admin','taxi'] },
        loadChildren: () => import('./tax-document/tax-document.module').then(m => m.TaxDocumentModule)
    },
    {
        path: 'mutuelles',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin'] },
        loadChildren: () => import('./mutuelles/mutuelles.module').then(m => m.MutuellesModule)
    },
     {
        path: 'cadeaux',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin'] },
        loadChildren: () => import('./cadeaux/cadeaux.module').then(m => m.CadeauxModule)
    },
    {
        path: 'teledeclarations',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse', 'employee','admin','taxi'] },
        loadChildren: () => import('./teledeclarations/teledeclarations.module').then(m => m.TeleDeclarationsModule)
    },
    {
        path: 'workers',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin'] },
        loadChildren: () => import('./workers/workers.module').then(m => m.WorkersModule)
    },
    {
        path: 'messages',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['admin', 'employee', 'nurse', 'opticien','nurseSub','taxi'] },
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule)
    },
    {
        path: 'quick-add-document',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse','nurseSub','taxi'] },
        loadChildren: () => import('./quick-add-document/quick-add-document.module').then(m => m.QuickAddDocumentModule)
    },
     {
        path: 'quick-add-ordinance',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse','nurseSub'] },
        loadChildren: () => import('./quick-add-ordinance/quick-add-ordinance.module').then(m => m.QuickAddOrdinanceModule)
    },
    {
        path: 'renewals',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse','nurseSub'] },
        loadComponent: () => import('./professionals/detail/renewals-anomalies/renewals-anomalies.component').then(m => m.RenewalsAnomaliesComponent)
    },
    // {
    //     path: 'contracts',
    //     canActivate: mapToCanActivate([AuthGuard]),
    //     data: { roles: ['nurse', 'opticien','medecin','taxi'] },
    //     loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule)
    // },
    {
        path: 'collaborators',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['nurse','nurseSub'] },
        loadChildren: () => import('./collaborators/collaborators.module').then(m => m.CollaboratorsModule)
    },
    {
        path: 'statistiques',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['opticien'] },
        loadChildren: () => import('./statistiques-opticien/statistiques-opticien.module').then(m => m.StatistiquesOpticienModule)
    },
    {
        path: 'suivifacturation',
        canActivate: mapToCanActivate([AuthGuard]),
        data: { roles: ['opticien'] },
        loadChildren: () => import('./factures-opticien/factures-opticien.module').then(m => m.FacturesOpticienModule)
    },
  ]}, {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      }]
    }
];
