<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <!-- <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button> -->
      </div>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">

      <ul class="navbar-nav">

        <li class="nav-item dropdown">
          <a class="nav-link" href="#message" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">question_answer</i>
            <span class="notification" *ngIf="session.unreadMessages.length">{{session.unreadMessages.length}}</span>
            <p>
              <span class="d-lg-none d-md-block">Notifications</span>
            </p>
          </a>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <li *ngFor="let item of session.unreadMessages">
              <a [routerLink]="['/messages']">
                <div class="date text-center">{{item.createdAt | date: 'EEEE dd MMMM yyyy'}}</div>
                <div class="author text-center" *ngIf="session.isNurse || session.isOpticien;else employeeContenu">
                  {{item.employeName}}</div>
                <ng-template #employeeContenu>
                  <div class="author text-center">{{item.nurseName}}</div>
                </ng-template>

              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item dropdown" *ngIf="session.unreadMessages">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="material-icons">question_answer</i>
            <span *ngIf="session.unreadMessages.length" class="notification">{{session.unreadMessages.length}}</span>
            <p class="hidden-lg hidden-md">
              Notifications
              <b class="caret"></b>
            </p>
          </a>
          <ul class="dropdown-menu  dropdown-menu-right" *ngIf="session.unreadMessages.length">
            <li *ngFor="let item of session.unreadMessages">
              <a [routerLink]="['/messages']">
                <div class="date">{{item.createdAt | date: 'EEEE dd MMMM yyyy'}}</div>
                <div class="author" *ngIf="session.isNurse || session.isOpticien;else employeeContenu">
                  {{item.employeName}}</div>
                <ng-template #employeeContenu>
                  <div class="author">{{item.nurseName}}</div>
                </ng-template>

              </a>
            </li>
          </ul>
        </li> -->
      </ul>
    </div>
  </div>
</nav>