
<div class="wrapper">
    <div class="sidebar" data-color="rose" data-background-color="dimgray" data-image="./assets/img/sidebar-3.jpg">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div id='bgimg' class="sidebar-background" style="background-image: url({{imageBackground}})"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <app-footer-cmp></app-footer-cmp>
        </div>
    </div>
    <app-fixedplugin></app-fixedplugin>
</div>
