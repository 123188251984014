import { Injectable } from '@angular/core';
import { Router, CanActivateChild, } from '@angular/router';
import { SessionService } from './session.service';


@Injectable()
export class AuthChildGuard implements CanActivateChild {
  constructor(private router: Router, private _session: SessionService) { }

  async canActivateChild() {

    const sessionStatus = await this._session.getSessionStatus();

    if (sessionStatus === true) {
      return true;
    }
    this.router.navigate(['/pages/login']);
    return false;

  }
}
