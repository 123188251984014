import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { Observable } from 'rxjs';
import { User } from '../shared/models/user';
import { RestService } from '../shared/services/rest.service';
import { SessionService } from '../shared/services/session.service';

import { Chart } from 'angular-highcharts';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    roles?: string[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/statistiques',
        title: 'Statistiques',
        type: 'link',
        icontype: 'dashboard',
        roles: ['opticien']
    },
    {
        path: '/suivifacturation',
        title: 'Suivi de mes facturations',
        type: 'link',
        icontype: 'list',
        roles: ['opticien']
    },
    {
        path: '/dashboard',
        title: 'Tableau de Bord',
        type: 'link',
        icontype: 'dashboard',
        roles: ['employee']
    },
    {
        path: '/professionals',
        title: 'Professionnels de santé',
        type: 'link',
        icontype: 'person',
        roles: ['admin', 'employee']
    },
    {
        path: '/patients',
        title: 'Patients',
        type: 'link',
        icontype: 'group',
        roles: ['nurse', 'medecin','nurseSub','taxi']
    },
    {
        path: '/workers',
        title: 'Employés',
        type: 'link',
        icontype: 'person_outline',
        roles: ['admin']
    },
    {
        path: '/teledeclarations',
        title: 'Télédéclarations',
        type: 'link',
        icontype: 'note',
        roles: ['admin', 'nurse', 'medecin', 'employee','taxi']
    },
    {
        path: '/ordinances',
        title: 'Ordonnances',
        type: 'link',
        icontype: 'border_color',
        roles: ['admin', 'nurse', 'employee','nurseSub']
    },
    {
        path: '/transports',
        title: 'Transports',
        type: 'link',
        icontype: 'local_taxi',
        roles: ['admin', 'taxi', 'employee']
    },
    {
        path: '/vehicles',
        title: 'Vehicles',
        type: 'link',
        icontype: 'local_taxi',
        roles: ['taxi']
    },
    {
        path: '/documents',
        title: 'Documents',
        type: 'link',
        icontype: 'attach_file',
        roles: ['employee', 'admin']
    },
    {
        path: '/collaborators',
        title: 'Mon cabinet',
        type: 'link',
        icontype: 'accessibility',
        roles: ['nurse','nurseSub']
    },
    {
        path: '/invoices',
        title: 'Factures',
        type: 'link',
        icontype: 'list',
        roles: ['nurse', 'opticien', 'medecin','taxi']
    },
    {
        path: '/attestation-fiscale',
        title: 'Attestation fiscale',
        icontype: 'library_books',
        type: 'link',
        roles: ['admin']
    },
    {
        path: '/attestation-fiscale/voir',
        title: 'Attestation fiscale',
        icontype: 'library_books',
        type: 'link',
        roles: ['nurse', 'opticien', 'medecin','taxi']
    },
    {
        path: '/motifs',
        title: 'Gestion des motifs de refus',
        icontype: 'highlight_off',
        type: 'link',
        roles: ['admin']
    },
    {
        path: '/mutuelles',
        title: 'Gestion des mutuelles',
        type: 'link',
        icontype: 'medical_services',
        roles: ['admin']
    },
    {
        path: '/cadeaux',
        title: 'Gestion du catalogue cadeaux',
        type: 'link',
        icontype: 'card_giftcard',
        roles: ['admin']
    },
    {
        path: '/renewals',
        title: 'Renouvellements & Anomalies',
        type: 'link',
        icontype: 'warning_amber',
        roles: ['nurse','nurseSub']
    },
    {
        path: '/messages',
        title: 'Messages',
        type: 'link',
        icontype: 'chat',
        roles: ['nurse', 'admin', 'employee', 'opticien', 'medecin','nurseSub','taxi']
    },
    {
        path: '/quick-add-ordinance',
        title: 'Ajout rapide d\'ordonnance',
        type: 'link',
        icontype: 'add',
        roles: ['nurse','nurseSub']
    },
    {
        path: '/quick-add-document',
        title: 'Ajout rapide de document',
        type: 'link',
        icontype: 'add',
        roles: ['nurse', 'medecin','nurseSub']
    },
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    public menuItems: RouteInfo[];
    ps: PerfectScrollbar;

    public userObs: Observable<User>;
    public user: User;
    public userType: string;

    constructor(
        public session: SessionService,
        private _router: Router,
        private _rest: RestService<User>
    ) {

    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    async ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

        this._rest.get("/users/me").subscribe({
            next: (data) => {
                this.user = new User(data);
                this.userType = this.user.role;
            },
            error: () => {
                this.user = this.session.user;
                this.userType = this.session.type;
            }
        }
        );
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    signout() {
        this.session.signout(() => {
            setTimeout(() => {
                this._router.navigate(['/pages/login']);
            }, 500);
        });
    }
}